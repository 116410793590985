<template>
  <div id="uav">
    <div class="uav-box">
      <div class="top"></div>
      <div class="center">
        <div class="one">
          <div>{{ contentArr.title }}</div>
          <div v-html="contentArr.message"></div>
          <!-- <p>{{ contentArr[0].textA }}</p>
          <p>{{ contentArr[0].textB }}</p> -->
        </div>
        <div class="two">
          <img src="../assets/bg-wisdom.png" alt="" />
        </div>
        <div class="three">
          <img :src="contentArr.backgroundImg" alt="" />
        </div>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div class="bottom-left-box">
            <a href="https://beian.miit.gov.cn/" target="123">
              黑ICP备20001581号-2
            </a>
          </div>
          <div class="bottom-left-box"></div>
        </div>
        <div class="bottom-right">
          <div class="bottom-right-left">
            业务联系电话：15946004541
          </div>
          <div class="bottom-rights">
            ©哈尔滨神州精英科技开发有限公司 版权所有
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { reactive } from "vue";
import { axios } from "../../request/axios";
export default {
  data() {
    return {
      contentArr: [
        {
          title: "神州精英",
          backgroundImg: "abc",
          message: "",
          // textB: "undefined",
          id: 1,
        },
      ],
    };
  },
  // setup() {
  //   let contentArr = reactive([
  //     {
  //       title: "神州精英",
  //       backgroundImg: "abc",
  //       textA: "undefined",
  //       textB: "undefined",
  //       id: 1,
  //     },
  //   ]);
  //   return {
  //     contentArr,
  //   };
  // },
  methods: {
    go(e) {
      window.location.href = e;
    },
  },
  created() {
    axios({
      method: "get",
      url: "/system/selectSystemEnt?title=无人机服务",
    }).then(
      (res) => {
        // console.log(res);
        let strArr = res.textA.split("&");
        res.message = "";
        for (let i = 0; i < strArr.length; i++) {
          res.message += `<p>${strArr[i]}</p>`;
        }
        this.contentArr = JSON.parse(JSON.stringify(res));
        // console.log(this.contentArr);
      },
      (err) => {
        console.error(err);
      }
    );
  },
};
</script>
<style lang="scss">
#uav {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    rgb(18, 158, 0) 0%,
    rgb(0, 77, 0) 15%,
    rgb(0, 51, 0) 40%
  );
  .uav-box {
    width: 100%;
    height: 100%;
    position: relative;
    .top {
      height: 7%;
    }
    .center {
      padding: 0.8rem 0 0 0.8rem;
      position: relative;
      width: 100%;
      height: 83%;
      .one {
        position: absolute;
        top: 6%;
        left: 12%;
        width: 53%;
        height: 100%;
        z-index: 40;
        div:nth-child(1) {
          color: white;
          line-height: 0.6rem;
          font-size: 0.25rem;
          font-weight: bolder;
          text-shadow: 2px 2px 2px black;
        }
        div:nth-child(2) {
          p {
            line-height: 0.6rem;
            font-size: 0.25rem;
            color: white;
            text-indent: 2em;
            text-shadow: 2px 2px 2px black;
          }
        }
      }
      .two {
        position: absolute;
        top: 44%;
        left: 50%;
        transform: translate(-40%, -40%);
        width: 100%;
        height: 100%;
        z-index: 30;
        img {
          width: 57%;
          height: 93%;
        }
      }
      .three {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: none;
        }
      }
    }
    .bottom {
      height: 10%;
      width: 100%;
      background: black;
      line-height: 0.6rem;
      display: flex;
      .bottom-left {
        flex: 1;
        display: flex;
        .bottom-left-box {
          //flex: 1;
          width: 4rem;
          padding: 10px 20px;
          text-align: left;
          color: white;
          z-index: 30;
          font-size: 0.25rem;
          > a {
            width: 100%;
            height: 100%;
            text-decoration: none;
            color: #fff;
          }
        }
      }
      .bottom-right {
        flex: 1;
        padding: 10px 20px;
        text-align: right;
        color: white;
        z-index: 30;
        font-size: 0.25rem;
        display: flex;
        .bottom-right-left {
          flex: 1;
          font-size: 0.25rem;
          color: #fff;
          text-align: left;
        }
        .bottom-rights {
          flex: 1;
          font-size: 0.25rem;
          color: #fff;
          text-align: right;
        }
      }
    }
  }
}
</style>
