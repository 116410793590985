<template>
  <div class="all-container">
    <!--页面的头部导航按钮-->
    <div class="container-header">
      <div class="btn-box">
        <div class="logo"></div>
        <button
          v-for="item in navNameArr"
          :key="'navBtn' + item.comp"
          @click="changeTopName(item.comp)"
          :class="{
            'active-btn':
              compOneState.compName == item.comp ||
              compSecondState.compName == item.comp,
          }"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <!--页面的主题内容-->
    <div class="container-content">
      <div class="content-box">
        <!--首页模块位置-->
        <div
          :class="{ transit: compOneState.topval != 50 }"
          :style="'top:' + compOneState.topval + '%'"
        >
          <component v-bind:is="compOneState.compName" />
        </div>
        <!--公司模块位置-->
        <div
          :class="{ transit: compSecondState.topval != 50 }"
          :style="'top:' + compSecondState.topval + '%'"
        >
          <component v-bind:is="compSecondState.compName" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Home from "./components/Home"; //引入首页模块
import Company from "./components/Company"; //引入公司模块
import Wisdom from "./components/Wisdom"; //引入智慧物联模块
import Uav from "./components/Uav"; //引入无人机模块
import Message from "./components/Message"; //引入信息安全模块
import Software from "./components/Software"; //引入软件制定模块
import Job from "./components/Job"; //引入软件制定模块
import Contactus from "./components/Contactus"; //引入联系我们模块
import { onMounted, reactive } from "vue"; //引入composition API ref

// 改变页面的显示模块
function changePage() {
  let compOneState = reactive({ compName: "Home", state: true, topval: 25 });
  let compSecondState = reactive({
    compName: undefined,
    state: false,
    topval: 50,
  });
  let timeStamp = undefined; //时间戳
  let oprateTime = 1000;
  let navNameArr = [
    //静态按钮数组
    { name: "首页", val: 0, comp: "Home" },
    { name: "关于精英", val: 0, comp: "Company" },
    { name: "数字农业", val: 0, comp: "Wisdom" },
    { name: "无人机服务", val: 0, comp: "Uav" },
    { name: "信息安全", val: 0, comp: "Message" },
    { name: "软件定制", val: 0, comp: "Software" },
    { name: "人才招募", val: 0, comp: "Job" },
    { name: "联系我们", val: 0, comp: "Contactus" },
  ];
  // 页面显示切换
  function changeTopName(val) {
    //如果当前已显示此组件将不会执行组件更改
    if (compOneState.state && compOneState.compName == val) return;
    if (compSecondState.state && compSecondState.compName == val) return;
    //判断点击间隔时间 在1.6秒之内点击 没有动画效果 超过1.6秒有动画效果
    !timeStamp
      ? (timeStamp = Date.now())
      : (oprateTime = Date.now() - timeStamp);

    if (oprateTime < 850) {
      //如果点击时间小于1.6秒不做动画只更改页面
      compOneState.state
        ? (compOneState.compName = val)
        : compOneState.compName;
      compSecondState.state
        ? (compSecondState.compName = val)
        : compSecondState.compName;
    } else {
      //操作时间大雨1.6秒
      timeStamp = Date.now(); //改变操作时间戳
      //那个为组建状态为false 就改变那个组件的名字
      compOneState.state
        ? compOneState.compName
        : (compOneState.compName = val);
      compSecondState.state
        ? compSecondState.compName
        : (compSecondState.compName = val);
      //并同时改变两个组件的位置状态通过过度实现动画的上下切换
      compOneState.topval = compOneState.topval - 25;
      compSecondState.topval = compSecondState.topval - 25;
      //当某一个组件的位置为0时通过移步操作让他回到50位置 并清空组件 等待下次调用
      compOneState.topval == 0
        ? setTimeout(() => {
            compOneState.topval = 50;
            compOneState.compName = undefined;
            //改变组件显示状态  用户能看件的组件为true 看不见的为false
            compOneState.state = !compOneState.state;
            compSecondState.state = !compSecondState.state;
          }, 850)
        : false;
      compSecondState.topval == 0
        ? setTimeout(() => {
            compSecondState.topval = 50;
            compSecondState.compName = undefined;

            //改变组件显示状态  用户能看件的组件为true 看不见的为false
            compOneState.state = !compOneState.state;
            compSecondState.state = !compSecondState.state;
          }, 850)
        : false;
    }
  }
  let domDiv = document.getElementById("app");
  onMounted(() => {
    domDiv.addEventListener(
      "mousewheel",
      (e) => {
        e.preventDefault();
      },
      false
    );
  });
  return { changeTopName, navNameArr, compOneState, compSecondState }; //输出函数和对应的变量
}

export default {
  components: { Home, Company, Wisdom, Uav, Message, Software, Job, Contactus }, //注册局部组件位置

  setup() {
    let {
      changeTopName,
      navNameArr,
      compOneState,
      compSecondState,
    } = changePage(); //执行函数获取暴露的变量

    return { changeTopName, navNameArr, compOneState, compSecondState }; //变量输出合并到data中
  },
};
</script>
<!--样式scss写-->
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div.all-container {
    //页面整体容器
    width: 100%;
    height: 100%;
    background: rgb(211, 223, 216);
    > div.container-header {
      //容器头部导航栏
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 7%;
      background: rgba(29, 31, 27, 0.4);
      z-index: 100;
      > div.btn-box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .logo {
          width: 11%;
          height: 80%;
          position: absolute;
          top: 10%;
          left: 3%;
          background: url(./assets/logo.png) center no-repeat;
          background-size: contain;
        }
        > button {
          @media screen and (min-width: 415px) {
            width: 5%;
          }
          @media screen and (max-width: 414px) {
            width: 14%;
            height: 100%;
            font-size: 8px;
          }
          height: 38%;
          font-size: 0.2rem;
          margin: 0 0.2rem;
          cursor: pointer;
          border: 0;
          background: transparent;
          color: #fff;
          font-weight: 100;
          outline: none;
          &:hover {
            color: rgb(0, 204, 255);
          }
          &.active-btn {
            color: rgb(0, 204, 255);
          }
        }
      }
    }
    > div.container-content {
      //容器主体
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        //不显示滚动条
        display: none;
      }
      > div.content-box {
        //页面溢出的容器
        width: 100%;
        height: 400%;
        position: absolute;
        top: -100%;
        left: 0;
        > div {
          width: 100%;
          height: 25%;
          border: 1px solid black;
          background: rgb(187, 187, 175);
          text-align: left;
          &.transit {
            transition: all 0.85s ease-in-out;
          }
          &:nth-child(1) {
            position: absolute;
            top: 25%;
            left: 0;
          }
          &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 0;
          }
        }
      }
    }
  }
}
</style>
