<template>
  <div id="job">
    <div class="top"></div>
    <div class="center">
      <div class="asideBox">
        <div class="aside">
          <div class="aside-box">
            <div class="aside-boxs" id="asideMenuUp">
              <ul class="asideMenu" :style="{ top: tops + 'px' }">
                <li
                  v-for="(item, index) in menuList"
                  :key="'asideMenu' + index"
                  class="asideMenu-box"
                >
                  <!-- 左侧的选择岗位按钮-->
                  <div
                    class="oneMenu"
                    :class="{ 'active-btn': isSubShow == index }"
                    @click="showToggle(index, item.job)"
                  >
                    <span>{{ item.job }}</span>
                  </div>
                  <!-- 右侧岗位描述，招聘要求-->
                </li>
              </ul>
            </div>
          </div>
          <div class="child">
            <div
              class="child-box"
              v-for="(item, index) in menuList"
              :key="'child' + index"
              v-show="isSubShow == index"
            >
              <h3 class="oneMenuChild">{{ item.job }}</h3>
              <p>{{ item.positionDescription }}</p>
              <p > <span class="title2">任职要求：</span>{{ item.jobRequirements }}</p>
              <p><span class="title2">应聘流程：</span>{{ item.recruitmentFlow }}</p>
              <p class="title1">招聘人数：{{ item.personNum }}</p>
              <p class="title1">
                招聘状态：{{ item.status == 1 ? "暂停招聘" : "招聘中" }}
              </p>
              <p class="title1">招聘开始时间：{{ item.startTime }}</p>
              <p class="title1">招聘截止日期：{{ item.stopTime }}</p>
              <p class="title1">综合素质：{{ item.synthesize }}</p>
              <p class="title1">待遇：{{ item.treatment }}</p>
            </div>
            <!--岗位申请的显示按钮-->
            <div class="btn" @click="fangda('ck')">岗位申请</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-left">
        <div class="bottom-left-box">
          <a href="https://beian.miit.gov.cn/" target="123">
            黑ICP备20001581号-2
          </a>
        </div>
        <div class="bottom-left-box"></div>
      </div>
      <!-- <div class="bottom-lefts" @click="go(this.urls)">
        <p>黑ICP备20001581号-2</p>
      </div> -->
      <div class="bottom-right">©哈尔滨神州精英科技开发有限公司 版权所有</div>
    </div>
    <!-- 岗位申请显示 -->
    <div class="fangda" id="amplification" v-show="fd">
      <div class="fangda-box" id="div1" :style="{ top: top + 'px' }">
        <!--关闭岗位申请-->
        <div class="gb" @click="guanbi('gb')">
          X
        </div>
        <div class="jl">
          <div class="jl-box">
            <div class="jl-box-img"></div>
            <!--form表单添加岗位申请列表-->
            <el-form
              :model="resume"
              status-icon
              ref="userAdd"
              label-position="left"
              class="demo-ruleForm"
            >
              <div class="jl-box-title">
                <div class="jl-box-title-box">
                  基本信息
                </div>
              </div>
              <el-form-item label="*应聘岗位">
                <span class="jobs">
                  {{ resume.job }}
                </span>
              </el-form-item>
              <el-form-item label="*姓名">
                <el-input
                  v-model="resume.name"
                  autocomplete="off"
                  placeholder="请输入姓名（中文）"
                ></el-input>
              </el-form-item>
              <el-form-item label="*性别">
                <el-radio v-model="resume.sex" label="男">男</el-radio>
                <el-radio v-model="resume.sex" label="女">女</el-radio>
              </el-form-item>
              <el-form-item label="*出生日期">
                <el-input
                  type="date"
                  class="input-time"
                  v-model="resume.dateBirth"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="*户口所在地">
                <el-input
                  v-model="resume.registeredPermanentResidence"
                  autocomplete="off"
                  placeholder="请输入户口所在地"
                ></el-input>
              </el-form-item>
              <el-form-item label="现居住地">
                <el-input
                  v-model="resume.presentAddress"
                  autocomplete="off"
                  placeholder="请输入现居住地"
                ></el-input>
              </el-form-item>
              <el-form-item label="*手机号码">
                <el-input
                  v-model="resume.phone"
                  autocomplete="off"
                  placeholder="请输入手机号码"
                ></el-input>
              </el-form-item>
              <el-form-item label="*电子邮箱">
                <el-input
                  v-model="resume.email"
                  autocomplete="off"
                  placeholder="请输入电子邮箱"
                ></el-input>
              </el-form-item>
              <div class="jl-box-title">
                <div class="jl-box-title-box">
                  教育背景
                </div>
              </div>
              <el-form-item label="*毕业院校">
                <el-input
                  v-model="resume.schoolGraduation"
                  autocomplete="off"
                  placeholder="请输入毕业院校"
                ></el-input>
              </el-form-item>
              <el-form-item label="*专业">
                <el-input
                  v-model="resume.specialty"
                  autocomplete="off"
                  placeholder="请输入专业"
                ></el-input>
              </el-form-item>
              <el-form-item label="*学历/学位">
                <el-radio v-model="resume.educationBackground" label="专科"
                  >专科</el-radio
                >
                <el-radio v-model="resume.educationBackground" label="本科"
                  >本科</el-radio
                >
                <el-radio v-model="resume.educationBackground" label="研究生"
                  >研究生</el-radio
                >
                <el-radio v-model="resume.educationBackground" label="硕士"
                  >硕士</el-radio
                >
                <el-radio v-model="resume.educationBackground" label="博士"
                  >博士</el-radio
                >
                <el-radio v-model="resume.educationBackground" label="博士后"
                  >博士后</el-radio
                >
              </el-form-item>
              <el-form-item label="*毕业时间">
                <el-input
                  class="input-time"
                  type="date"
                  v-model="resume.graduateDate"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="专业培训情况">
                <el-input
                  type="textarea"
                  :rows="2"
                  show-word-limit
                  maxlength="300"
                  placeholder="请简要的说明自己的专业培训情况，内容不超过300字"
                  v-model="resume.train"
                >
                </el-input>
              </el-form-item>
              <div class="jl-box-title">
                <div class="jl-box-title-box">
                  *工作经历
                </div>
              </div>
              <el-form-item label="">
                <el-input
                  type="textarea"
                  :rows="4"
                  show-word-limit
                  maxlength="300"
                  placeholder="请简要的说明自己的工作经历，内容不超过300字"
                  v-model="resume.workExperience"
                >
                </el-input>
              </el-form-item>
              <div class="jl-box-title">
                <div class="jl-box-title-box" style="background-size:130% 100%">
                  自我能力简介
                </div>
              </div>
              <el-form-item label="">
                <el-input
                  type="textarea"
                  :rows="4"
                  show-word-limit
                  placeholder="请在300个字内说明自己能力"
                  maxlength="300"
                  v-model="resume.briefIntroduction"
                >
                </el-input>
              </el-form-item>
              <div class="jl-box-title">
                <div class="jl-box-title-box" style="background-size:130% 100%">
                  项目业绩网址
                </div>
              </div>
              <el-form-item label="">
                <el-input
                  type="textarea"
                  :rows="4"
                  maxlength="300"
                  show-word-limit
                  placeholder="请输入内容，最多300字"
                  v-model="resume.projectUrl"
                >
                </el-input>
              </el-form-item>
            </el-form>
            <div class="submit">
              <el-button :plain="true" @click="submit()" style="color:#fff"
                >提交</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axios } from "../../request/axios";
export default {
  data() {
    return {
      // 放大
      fd: false,
      top: 0,
      tops: 0,
      resumes: "",
      name: "",
      menuList: [
        { job: "1" },
        { job: "2" },
        { job: "3" },
        { job: "4" },
        { job: "5" },
        { job: "6" },
        { job: "7" },
        { job: "8" },
        { job: "9" },
        { job: "10" },
        { job: "11" },
        { job: "12" },
        { job: "13" },
        { job: "14" },
        { job: "15" },
        { job: "16" },
        { job: "17" },
        // { job: "18" },
        // { job: "19" },
        // { job: "20" },
        // { job: "21" },
      ],
      isSubShow: 0,
      isSubShows: 0,
      resume: {
        name: "",
        sex: "男",
        dateBirth: "",
        registeredPermanentResidence: "",
        presentAddress: "",
        phone: "",
        email: "",
        schoolGraduation: "",
        specialty: "",
        educationBackground: "专科",
        graduateDate: "",
        train: "",
        workExperience: "",
        briefIntroduction: "",
        projectUrl: "",
        fileName: "",
        post: "",
        job: "",
      },
      url: "http://elitechina-hrb.com",
      urls: "http://shenzhoujingying.com",
      radio: "1",
    };
  },
  methods: {
    // 点击展开折叠菜单事件
    showToggle(id, name) {
      this.isSubShow = id;
      this.resume.job = name;
      // console.log(this.resume.job)
    },

    fangda(pname) {
      if (pname === "ck") {
        this.fd = true;
      }
    },
    guanbi(pname) {
      if (pname === "gb") {
        this.fd = false;
      }
    },
    //添加页面滚轮滚动事件
    //判断向上还是向下
    scrollDs() {
      let domDiv = document.getElementById("amplification");
      domDiv.addEventListener(
        "mousewheel",
        (e) => {
          e.preventDefault();
          if (e.wheelDelta > 0 && this.top < 0) {
            let top = this.top + 120;
            this.top = top;
          }
          if (e.wheelDelta < 0 && this.top > -1690) {
            let top = this.top - 120;
            this.top = top;
          }
        },
        false
      );
    },
    scrollDsa() {
      let domDiv = document.getElementById("asideMenuUp");
      domDiv.addEventListener(
        "mousewheel",
        (e) => {
          // console.log(e.wheelDelta);
          // console.log(this.tops);
          e.preventDefault();
          if (e.wheelDelta > 0 && this.tops < 0) {
            let tops = this.tops + 120;
            this.tops = tops;
          }
          if (e.wheelDelta < 0 && this.tops > -480) {
            let tops = this.tops - 120;
            this.tops = tops;
          }
        },
        false
      );
    },
    submit() {
      let yourname = this.resume.name.replace(/[^\u4E00-\u9FA5]/g, "");
      if (yourname == "") {
        this.$message({
          message: "姓名不可为空请输入中文名字",
          type: "warning",
        });
        return;
      }
      if (this.resume.dateBirth == "") {
        this.$message({
          message: "请选择出生日期",
          type: "warning",
        });
        return;
      }
      if (this.resume.registeredPermanentResidence == "") {
        this.$message({
          message: "请输入户口所在地",
          type: "warning",
        });
        return;
      }
      if (this.resume.phone == "") {
        this.$message({
          message: "请输入手机号，",
          type: "warning",
        });
        return;
      }
      if (!Number.isInteger(parseInt(this.resume.phone.trim()))) {
        this.$message({
          message: "有非法字符，请输入数字11位电话号码",
          type: "warning",
        });
        return;
      }
      if ((this.resume.phone + "").length !== 11) {
        this.$message({
          message: "您输入的电话号码位数错误，请输入11位电话号码",
          type: "warning",
        });
        return;
      }
      if (
        /^([a-zA-Z]|[0-9]){4,10}@[a-zA-Z0-9]{2,5}(\.)([a-zA-Z]{2,4})$/.test(
          this.resume.email
        ) == false
      ) {
        this.$message({
          message: "邮箱格式错误请输入正确的邮箱",
          type: "warning",
        });
        return;
      }
      if (this.resume.workExperience == "") {
        this.$message({
          message: "请添加工作经历",
          type: "warning",
        });
      }
      let token = "Bearer " + sessionStorage.getItem("token");
      let resume = this.resume;
      axios({
        method: "post",
        headers: {
          Authorization: token,
        },
        url: "/positionManage/uploadResume",
        data: resume,
      })
        .then((res) => {
          let code = res.code;
          if (code === 200) {
            this.guanbi("gb");
            this.$message({
              type: "success   ",
              message: "简历上传成功",
            });
          }
          if (code === 1011006 || code === 1011004 || code === 1011008) {
            this.$alert(
              "登录超时，登录已过期,或多账号登录，点击确定重新登录",
              "温馨提示",
              {
                confirmButtonText: "确定",
                callback: () => {
                  sessionStorage.setItem("token", "");
                  sessionStorage.setItem("isLogin", false);
                  sessionStorage.setItem("power", 0);
                  sessionStorage.setItem("userID", 0);
                  this.$store.commit("changeIsLoginFalse");
                  this.$router.push("/login");
                  this.$message({
                    type: "info",
                    message: `请重新登录`,
                  });
                },
              }
            );
            return;
          }
          if (code === 1016002) {
            this.$alert("服务器出现异常，请联系管理员", "温馨提示", {
              confirmButtonText: "确定",
            });
            return;
          }
        })
        .catch((err) => {
          this.listLoading = false;
          this.$alert("请求数据失败,请刷新网页重试", "温馨提示", {
            confirmButtonText: "确定",
          });
          console.log(err);
        });
    },
    go(e) {
      window.location.href = e;
    },
  },
  mounted() {
    this.scrollDs();
    this.scrollDsa();
    let token = "Bearer " + sessionStorage.getItem("token");
    axios({
      method: "get",
      headers: {
        Authorization: token,
      },
      url: "/rec/recruitingL",
    })
      .then((res) => {
        console.log(res);
        let code = res.code;
        if (code === 1011006 || code === 1011004 || code === 1011008) {
          this.$alert(
            "登录超时，登录已过期,或多账号登录，点击确定重新登录",
            "温馨提示",
            {
              confirmButtonText: "确定",
              callback: () => {
                sessionStorage.setItem("token", "");
                sessionStorage.setItem("isLogin", false);
                sessionStorage.setItem("power", 0);
                sessionStorage.setItem("userID", 0);
                this.$store.commit("changeIsLoginFalse");
                this.$router.push("/login");
                this.$message({
                  type: "info",
                  message: `请重新登录`,
                });
              },
            }
          );
          return;
        }
        if (code === 1016002) {
          this.$alert("服务器出现异常，请联系管理员", "温馨提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        this.menuList = res.data;
        this.resume.job = res.data[0].job;
      })
      .catch((err) => {
        this.listLoading = false;
        this.$alert("请求数据失败,请刷新网页重试", "温馨提示", {
          confirmButtonText: "确定",
        });
        console.log(err);
      });
  },
  beforeUnmount() {
    this.fd = false;
  },
};
</script>
<style lang="scss" scoped>
$menuBackColor: #f1f1f1;
$menuListH2: #8fbfef;
#job {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    rgb(18, 158, 0) 0%,
    rgb(0, 77, 0) 15%,
    rgb(0, 51, 0) 40%
  );
  position: relative;
  .top {
    height: 7%;
  }
  .center {
    position: relative;
    width: 100%;
    height: 83%;
    .asideBox {
      height: 100%;
      width: 100%;
      background: url("../assets/bg2.jpg");
      background-size: 100% 100%;
      .aside {
        background-color: rgba(0, 0, 0, 0.4rem);
        height: 100%;
        width: 100%;
        display: flex;
        position: relative;
        .aside-box {
          width: 18%;
          height: 100%;
          padding: 0.2rem 0.6rem;
          position: absolute;
          .aside-boxs {
            width: 100%;
            height: 100%;
            position: relative;
            .asideMenu {
              width: 100%;
              position: relative;
              .oneMenu {
                text-align: center;
                width: 100%;
                height: 0.7rem;
                line-height: 0.7rem;
                font-size: 0.3rem;
                font-weight: normal;
                color: #ffffff;
                background: #8fbfef;
                margin-top: 0.1rem;
                border-radius: 10px;
                &:hover {
                  background: rgb(0, 150, 250);
                  cursor: pointer;
                  color: yellow;
                }
                &:active {
                  color: yellow;
                  background: rgb(0, 0, 0);
                  box-shadow: 0 0 10px rgb(7, 193, 250) inset;
                }
              }
              .active-btn {
                background: rgb(0, 150, 250);
                color: yellow;
                box-shadow: 0 0 10px rgb(7, 193, 250) inset;
              }
            }
          }
        }
        .child {
          // flex: 8;
          width: 80%;
          height: 85%;
          position: absolute;
          right: 0.8rem;
          top: 0.8rem;
          border: 1px solid rgb(131, 131, 131);
          box-shadow: 0px 0px 20px rgb(194, 196, 194);
          padding: 0.6rem;
          padding-bottom: 0.8rem;
          .child-box {
            width: 100%;
            height: 100%;
            position: relative;
            background: #1b1b1b62;
            border: 1px solid rgb(131, 131, 131);
            padding: 0.2rem;
            box-shadow: 0px 0px 20px rgb(196, 194, 194);
            font-size: 14px;
            // overflow-y: scroll;
            .oneMenuChild {
              line-height: 40px;
              color: white;
              font-size: 0.3rem;
            }
            .title2 {
              font-size: 16px;
              font-weight: 900;
            }
            p {
              line-height: 25px;
              color: rgb(243, 242, 239);
            }
          }
          .btn {
            border: none;
            background-color: cornflowerblue;
            color: cornsilk;
            padding: 0.15rem 0.5rem;
            border-radius: 0.2rem;
            width: 11%;
            font-size: 0.25rem;
            font-weight: 600;
            text-align: center;
            position: absolute;
            right: 5%;
            bottom: 1%;
            cursor: pointer;
            &:hover {
              background: rgb(0, 150, 250);
              cursor: pointer;
              color: yellow;
            }
            &:active {
              color: yellow;
              background: rgb(0, 0, 0);
              box-shadow: 0 0 10px rgb(7, 193, 250) inset;
            }
          }
          .child-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(196, 196, 196, 0.329);
          }
        }
      }
    }
  }
  .bottom {
    height: 10%;
    width: 100%;
    background: black;
    line-height: 0.6rem;
    display: flex;
    .bottom-left {
      flex: 1;
      display: flex;
      .bottom-left-box {
        //flex: 1;
        width: 4rem;
        padding: 10px 20px;
        text-align: left;
        color: white;
        z-index: 30;
        font-size: 0.25rem;
                > a {
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: #fff;
        }
      }
    }
    .bottom-right {
      flex: 1;
      padding: 10px 20px;
      text-align: right;
      color: white;
      z-index: 30;
      font-size: 0.25rem;
    }
  }
  .fangda {
    width: 100%;
    height: 83%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 7%;
    left: 0;
    .fangda-box {
      position: absolute;
      width: 60%;
      left: 20%;
      background: #fff;
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      .gb {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        line-height: 30px;
        color: rgb(255, 255, 255);
        background: #8a8888;
        cursor: pointer;
        &:hover {
          background: rgb(0, 0, 0);
          cursor: pointer;
          color: #fff;
        }
        &:active {
          color: #fff;
          background: rgb(0, 0, 0);
        }
      }
      .jl {
        margin: 0 auto;
        width: 95%;
        padding: 1rem 0.2rem;
        overflow: hidden;
        background: url(../assets/img/bj.png);
        background-size: 100% 100%;
        .jl-box {
          width: 80%;
          height: 100%;
          margin: 0 auto;
          .jl-box-img {
            width: 100%;
            height: 1rem;
            background: url(../assets/img/title-img.png);
            background-size: 100% 100%;
          }
          .jl-box-title {
            width: 100%;
            height: 1.7rem;
            padding: 0.5rem 0;
            .jl-box-title-box {
              width: 100%;
              height: 100%;
              line-height: 0.65rem;
              padding: 0 0.2rem;
              font-size: 0.25rem;
              color: #fff;
              background: url(../assets/img/title.png);
              background-size: 100% 100%;
            }
          }
          .el-form-item__content {
            .jobs {
              height: 100%;
              display: inline-block;
              font-size: 0.3rem;
            }
          }
          > div {
            width: 100%;
            margin: 0.2rem 0;
            > .title {
              width: 80%;
              margin-left: 10%;
              background-image: linear-gradient(
                to right,
                rgb(165, 165, 165),
                rgba(228, 228, 228, 0.726)
              );
              border-left: 5px solid rgb(192, 242, 255);
              font-size: 0.2rem;
              padding: 0.1rem;
            }
            > .titleChild {
              width: 80%;
              margin-left: 10%;
              border-bottom: 1px solid;
              padding: 0.6%;
              span {
                border-bottom: 1px solid rgb(0, 217, 255);
                padding: 0.7%;
              }
            }
            > .hr {
              height: 1px;
              width: 80%;
              margin: 5px auto;
              background-color: black;
            }
            > ul {
              width: 80%;
              margin-left: 10%;
              li {
                width: 100%;
                height: 0.9rem;
                line-height: 0.9rem;
                position: relative;
                .left {
                  width: 20%;
                  height: 100%;
                  display: inline-block;
                  line-height: 0.9rem;
                  font-size: 0.2rem;
                }
                .right {
                  width: 80%;
                  display: inline-block;
                  line-height: 0.9rem;
                  > input {
                    width: 4rem;
                    height: 0.4rem;
                    padding: 0 0.1rem;
                  }
                  #man {
                    width: 0.5rem;
                    height: 0.15rem;
                  }
                  #woman {
                    width: 0.5rem;
                    height: 0.15rem;
                  }
                  > textarea {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
            .fgx {
              padding: 0.5rem 0;
              width: 80%;
              margin-left: 10%;
              border-bottom: 1px dashed;
            }
          }
          .submit {
            width: 100%;
            text-align: center;
            > button {
              width: 1rem;
              height: 0.5rem;
              background: rgb(0, 132, 255);
            }
          }
        }
      }
    }
  }

  .el-form-item {
    margin-top: 20px;
    padding-left: 0.3rem;
    text-align: left !important;
    .el-form-item__label {
      text-align: left !important;
    }
  }
  .el-input {
    width: 80%;
  }
  .el-input__inner {
    width: 80%;
  }
  .el-textarea {
    width: 90%;
  }
  .input-time {
    width: 25%;
  }
  .el-form-item__content {
    width: 85%;
  }
}
</style>
