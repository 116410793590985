<template>
  <div id="company">
    <div class="dian">
      <ul @mouseenter="mouseOver" @mouseleave="mouseOut">
        <li
          @mouseenter="
            (e) => {
              e.stopPropagation();
              e.preventDefault();
            }
          "
          v-for="(item, index) in contentArr"
          :key="index"
          :class="{
            'active-dian':
              topval == item.val || (topval == 300 && item.val == 0),
          }"
          @click="jump(index)"
        ></li>
      </ul>
    </div>
    <div class="company-box">
      <div class="top"></div>
      <div class="center">
        <div
          class="center-box"
          :style="'top:-' + topval + '%'"
          :class="{
            'trans-active': topval !== 0,
            'trans-active': topvala !== 0,
          }"
        >
          <div
            class="wn"
            v-for="(item, index) in contentArr"
            :key="index + item.id + 'wnKuangjia'"
          >
            <div class="left">
              <div class="text">
                <div class="one">
                  <p>{{ item.title }}</p>
                  <div v-html="item.message"></div>
                </div>
                <div class="two">
                  <img src="../assets/bg-gongsi2.png" alt="" />
                </div>
              </div>
            </div>
            <div class="right" :style="'background:url(' + item.rightImg + ')'">
              <!-- <img :src="item.rightImg" alt="" style="width:100px; height:100px"/> -->
            </div>
          </div>
          <div class="wn">
            <div class="left">
              <div class="text">
                <div class="one">
                  <p>{{ contentArr[0].title }}</p>
                  <div v-html="contentArr[0].message"></div>
                </div>
                <div class="two">
                  <img src="../assets/bg-gongsi2.png" alt="" />
                </div>
              </div>
            </div>
            <div
              class="right"
              :style="'background:url(' + contentArr[0].rightImg + ')'"
            >
              <!-- <img src="../assets/tu1.png" alt="" /> -->
            </div>
          </div>
        </div>
      </div>
      <div class="bottom1">
        <div class="bottom1-left">
          <div class="bottom1-left-box">
            <a href="https://beian.miit.gov.cn/" target="123">
              黑ICP备20001581号-2
            </a>
          </div>
          <div class="bottom1-left-boxs"></div>
        </div>
        <div class="bottom1-right">
          ©哈尔滨神州精英科技开发有限公司 版权所有
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axios } from "../../request/axios";
export default {
  data: function() {
    return {
      contentArr: [
        {
          id: 1,
          rightImg: require("../assets/tu1.png"),
          message: `<p>哈尔滨神州精英科技开发有限公司是国家高新技术企业，位于哈尔滨
                    经济技术开发区中国云谷软件园。公司专注于物联网智能应用系统开发、
                    集成与运维，主营业务包括智慧物联系统研发与集成、无人机数据服务、
                    信息安全系统建设和软件系统开发定制等方向，通过智能物联网技术、遥
                    感技术、地理信息系统技术、数据安全技术及深度学习技术与数字中台技
                    术，服务于农业、林业、水利、气象、生态环境和应急管理等行业的大数
                    据应用。</p><p>神州精英公司是国家涉密信息系统集成乙级资质企业和“双软”认证
                    企业，并拥有一批具有自主知识产权的专利与软件著作权。企业技术团队
                    以研发人员核心，以开发人员为主体，以中国科学院、中国农林科学院及
                    东北农业大学、东北林业大学等一批国内知名科研教学单位为依托，致力
                    于新一代智慧物联系统的研发、实施与服务</p>`,
          val: 0,
          title: "关于精英 ",
        },
        {
          id: 2,
          rightImg: require("../assets/tu2.png"),
          message: `<img src=${require("../assets/gs-lb1.png")} alt="" />`,
          val: 100,
          title: "企业资质",
        },
        {
          id: 3,
          rightImg: require("../assets/tu3.png"),
          message: `<img src=${require("../assets/gs-lb2.png")} alt="" style="width:100%;height:100%"/>`,
          val: 200,
          title: "知识产权",
        },
      ],
      url: "http://shenzhoujingying.com",
      urls: "http://elitechina-hrb.com",
      num: 0,
      topval: 0,
      topvala: 0,
      timer: null,
    };
  },
  computed: {
    transform() {
      return "translateY(-" + this.num * this.height + "%)";
    },
  },

  methods: {
    lunbo() {
      this.timer = setInterval(() => {
        if (this.topval >= 300) {
          this.topval = 0;
        } else {
          this.topval += 100;
        }
      }, 3500);
    },
    mouseOver(e) {
      e.stopPropagation();

      // console.log(e.target, "进");
      clearInterval(this.timer);
      this.topvala = 1;
      this.timer = null;
    },
    mouseOut(e) {
      e.stopPropagation();
      if (e.target.nodeName == "LI") {
        return;
      }

      // console.log(e.target, "出");
      clearInterval(this.timer);
      this.topvala = 0;
      this.lunbo();
    },
    jump(index) {
      // console.log(index);
      // console.log(this.topval);
      this.topval = 100 * index;
    },
  },
  mounted() {
    this.lunbo();
  },
  created() {
    axios({
      method: "get",
      url: "/elite/selectElite",
    }).then(
      (res) => {
        // console.log(res)
        for (let i = 0; i < res.length; i++) {
          res[i].val = i * 100;
          if (res[i].text1 == "") {
            res[i].message = `<img src=${res[i].centreImg}  alt="" />`;
          } else {
            let strArr = res[i].text1.split("&");
            res[i].message = `<p>${strArr[0]}</p><p>${strArr[1]}</p>`;
          }
        }
        this.contentArr = JSON.parse(JSON.stringify(res));
        // console.log(this.contentArr)
      },
      (err) => {
        console.error(err);
      }
    );
  },
};
</script>

<style lang="scss">
#company {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    rgb(18, 158, 0) 0%,
    rgb(0, 77, 0) 15%,
    rgb(0, 51, 0) 40%
  );
  position: relative;
  .dian {
    width: 1rem;
    height: 30%;
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
    ul {
      width: 100%;
      height: 100%;
      list-style: none;

      li {
        width: 0.2rem;
        height: 0.2rem;
        border-radius: 50%;
        background: #fff;
        margin: 0.7rem 0;
        &.active-dian {
          background: #6b8e23;
        }
      }
    }
  }
  .company-box {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .top {
    height: 7%;
  }
  .center {
    padding: 0.8rem 0 0 0.8rem;
    position: relative;
    width: 100%;
    height: 83%;
    .center-box {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 400%;
      &.trans-active {
        transition: all 0.5s linear;
      }
      .wn {
        height: 25%;
        display: flex;

        .left {
          padding: 0.25rem 1.5rem;
          color: white;
          flex: 3;
          display: flex;
          .text {
            flex: 5;
            position: relative;
            > div {
              position: absolute;
              left: 60px;
              top: 0;
              width: 100%;
              height: 100%;
              &:nth-child(1) {
                z-index: 10;
                padding: 0.5rem;
                line-height: 0.7rem;
                font-size: 0.25rem;
                height: 100%;
                width: 100%;
                overflow-y: auto;
                > p {
                  height: 10%;
                  width: 100%;
                  font-size: 0.25rem;
                  font-weight: bolder;
                }
                > div {
                  height: 90%;
                  width: 90%;
                  > img {
                    width: 100%;
                    height: 92%;
                  }
                  > p {
                    width: 100%;
                    // height: 45%;
                    text-indent: 2em;
                  }
                }
              }
              &:nth-child(2) {
                z-index: 11;
                img {
                  height: 100%;
                  width: 90%;
                }
              }
            }
          }
        }
        .right {
          flex: 1.5;
          width: 100%;
          height: 100%;
          background-size: cover !important;
          background-position: center;
          transition: all 0.6s;
        }
        .right:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  .bottom1 {
    height: 10%;
    width: 100%;
    background: black;
    line-height: 0.6rem;
    display: flex;
    .bottom1-left {
      flex: 1;
      display: flex;
      .bottom1-left-box {
        //flex: 1;
        width: 4rem;
        padding: 10px 20px;
        text-align: left;
        color: white;
        z-index: 30;
        font-size: 0.25rem;
        > a {
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: #fff;
        }
      }
    }
    .bottom1-right {
      flex: 1;
      padding: 10px 20px;
      text-align: right;
      color: white;
      z-index: 30;
      font-size: 0.25rem;
    }
  }
}
</style>
