<template>
  <div id="home">
    <div
      class="slide-pictrue"
      :style="'left:-' + leftVal + '%'"
      :class="{ 'trans-active': leftVal != 0, 'trans-active1': leftVala != 0 }"
    >
      <div class="slide-box">
        <div v-for="(item, index) in pictrueArr" :key="'pictrueIndex' + index">
          <img :src="item.background" alt="" />
          <div class="text" :style="'background:url(' + item.element + ')'">
            <span>{{ item.title[0] }}</span>
            <span>{{ item.title[1] }}</span>
          </div>
        </div>
        <div>
          <img :src="pictrueArr[0].background" alt="" />
          <div
            class="text"
            :style="'background:url(' + pictrueArr[0].element + ')'"
          >
            <span>{{ pictrueArr[0].title[0] }}</span>
            <span>{{ pictrueArr[0].title[1] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="select-point">
      <div class="point-box">
        <ul @mouseenter="mouseEnter" @mouseleave="mouseOut">
          <li
            v-for="(item, index) in pictrueArr"
            :key="'pointIndex' + index"
            :class="{
              'active-back1': index == 0 && leftVal == num,
              'active-back': leftVal == item.val,
            }"
          ></li>
        </ul>
      </div>
    </div>
    <div class="direct-btn">
      <div class="direct-btn-box">
        <div
          @mouseenter="mouseEnter"
          @mouseleave="mouseOut"
          @click="mouseClick(0)"
        ></div>
        <div
          @mouseenter="mouseEnter"
          @mouseleave="mouseOut"
          @click="mouseClick(1)"
        ></div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-left">
        <div class="bottom-left-box">
          <a href="https://beian.miit.gov.cn/" target="123">
            黑ICP备20001581号-2
          </a>
        </div>
        <!-- <div class="bottom-left-box"></div> -->
      </div>
      <!-- <div class="bottom-right">
        ©哈尔滨神州精英科技开发有限公司 版权所有
      </div> -->
    </div>
  </div>
</template>

<script>
import { axios } from "../../request/axios";
import { onMounted, ref, reactive, computed } from "vue";
function changePoint() {
  let pictrueArr = reactive([
    {
      id: "p1",
      val: 0,
      title: "",
      background: require("../assets/p1.png"),
      element: require("../assets/bg-sy.png"),
    },
    {
      id: "p2",
      val: 100,
      title: "",
      background: require("../assets/p2.png"),
      element: require("../assets/bg-sy.png"),
    },
    {
      id: "p3",
      val: 200,
      title: "",
      background: require("../assets/p3.png"),
      element: require("../assets/bg-sy.png"),
    },
  ]);
  let timer = null;
  let leftVal = ref(0);
  let leftVala = ref(0);
  let num = computed(() => pictrueArr.length * 100);
  function changeLeftVal() {
    timer = setInterval(() => {
      // console.log(leftVal.value)
      // console.log(num.value)
      if (leftVal.value == num.value - 100) {
        leftVal.value += 100;
        setTimeout(() => {
          leftVal.value = 0;
        }, 550);
      } else {
        leftVal.value += 100;
      }
    }, 3500);
  }
  function mouseEnter() {
    clearInterval(timer);
    leftVala.value = 1;
  }
  function mouseOut() {
    changeLeftVal();
    leftVala.value = 0;
  }
  function mouseClick(name) {
    if (name == 1) {
      if (leftVal.value == num.value - 100) {
        leftVal.value = num.value - 100;
      } else {
        leftVal.value += 100;
      }
    } else {
      if (leftVal.value <= 0) {
        leftVal.value = 0;
      } else {
        leftVal.value -= 100;
      }
    }
  }
  onMounted(() => {
    changeLeftVal();
    document.addEventListener("visibilitychange", function() {
      if (document.visibilityState == "hidden") {
        clearInterval(timer);
      } else if (document.visibilityState == "visible") {
        clearInterval(timer);
        changeLeftVal();
      }
    });
  });

  return {
    num,
    pictrueArr,
    leftVal,
    leftVala,
    changeLeftVal,
    timer,
    mouseEnter,
    mouseOut,
    mouseClick,
  };
}
export default {
  setup() {
    let {
      num,
      pictrueArr,
      leftVal,
      leftVala,
      changeLeftVal,
      timer,
      mouseEnter,
      mouseOut,
      mouseClick,
    } = changePoint();

    axios({
      method: "get",
      url: "/home/selectHome",
    }).then(
      (res) => {
        for (let i = 0; i < res.length; i++) {
          res[i].val = i * 100;
          res[i].title = res[i].title.split(",");
          pictrueArr[i] = JSON.parse(JSON.stringify(res[i]));
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return {
      num,
      pictrueArr,
      leftVal,
      leftVala,
      changeLeftVal,
      timer,
      mouseEnter,
      mouseOut,
      mouseClick,
    };
  },
};
</script>

<style lang="scss" scoped>
#home {
  width: 100;
  height: 100%;
  background: url("../assets/p1.png");
  position: relative;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  > div.slide-pictrue {
    width: 800%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &.trans-active {
      transition: all 0.5s linear;
    }
    &.trans-active1 {
      transition: all 0.5s linear;
    }
    > div.slide-box {
      width: 100%;
      height: 100%;
      display: flex;
      > div {
        height: 100%;
        width: 12.5%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        > .text {
          width: 55%;
          background-size: 100% 100% !important;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 1rem;
          color: white;
          > span {
            font-size: 0.8rem;
            font-weight: 900;
            display: block;
            text-align: center;
            text-shadow: 2px 2px 2px black;
            letter-spacing: 10px;
          }
        }
      }
    }
  }
  > div.select-point {
    width: 100%;
    height: 10%;
    position: absolute;
    bottom: 0;
    left: 0;
    // background: seagreen;
    > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      > ul {
        height: 100%;
        @media screen and (min-width: 415px) {
          width: 15%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          > li {
            width: 0.16rem;
            height: 0.16rem;
            border-radius: 50%;
            background: #fff;
            &.active-back,
            &.active-back1 {
              background: rgb(4, 199, 248);
            }
          }
        }
        @media screen and (max-width: 414px) {
          width: 40%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          > li {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: #fff;
            &.active-back,
            &.active-back1 {
              background: rgb(4, 199, 248);
            }
          }
        }
      }
    }
  }
  > div.direct-btn {
    position: absolute;
    top: 35%;
    left: 0;
    width: 100%;
    height: 30%;
    // background: brown;
    z-index: 300;
    > div.direct-btn-box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        cursor: pointer;
        @media screen and (max-width: 414px) {
          &:nth-child(1) {
            border: 0.8rem solid transparent;
            border-right: 0.8rem solid rgba(6, 174, 252, 0.6);
            margin-left: 0.5rem;
            &:hover {
              border-right: 0.8rem solid rgba(6, 174, 252, 0.9);
            }
          }
          &:nth-child(2) {
            border: 0.8rem solid transparent;
            border-left: 0.8rem solid rgba(6, 174, 252, 0.6);
            margin-right: 0.5rem;
            &:hover {
              border-left: 0.8rem solid rgba(6, 174, 252, 0.9);
            }
          }
        }
        @media screen and (min-width: 415px) {
          &:nth-child(1) {
            border: 0.3rem solid transparent;
            border-right: 0.3rem solid rgba(6, 174, 252, 0.6);
            margin-left: 0.5rem;
            &:hover {
              border-right: 0.3rem solid rgba(6, 174, 252, 0.9);
            }
          }
          &:nth-child(2) {
            border: 0.3rem solid transparent;
            border-left: 0.3rem solid rgba(6, 174, 252, 0.6);
            margin-right: 0.5rem;
            &:hover {
              border-left: 0.3rem solid rgba(6, 174, 252, 0.9);
            }
          }
        }
      }
    }
  }
  .bottom {
    height: 7%;
    width: 20%;
    background: rgba(0, 0, 0, 0.192);
    line-height: 0.6rem;
    display: flex;
    position: absolute;
    bottom: 0;
    .bottom-left {
      flex: 1;
      display: flex;
      .bottom-left-box {
        //flex: 1;
        width: 4rem;
        padding: 10px 20px;
        text-align: left;
        color: white;
        z-index: 30;
        font-size: 0.25rem;
        > a {
          //display: inline-block;
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: #fff;
          line-height: 0.6rem;
        }
      }
    }
    .bottom-right {
      flex: 1;
      padding: 10px 20px;
      text-align: right;
      color: white;
      z-index: 30;
      font-size: 0.25rem;
    }
  }
}
</style>
