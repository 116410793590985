import axios from 'axios'

// 创建 axios 实例
const service = axios.create({
  // baseURL: 'http://10.0.0.7:8008', // api base_url
  // baseURL:'http://121.36.29.35:8081/guanwang',
  baseURL:'http://121.36.29.35:8001/', 
  timeout: 6000 // 请求超时时间
})

const err = (error) => {
  
  if (error.response) {
    const data = error.response.data
    // const token = Vue.ls.get(ACCESS_TOKEN)

    if (error.response.status === 403) {
      console.log('服务器403啦，要重新登录！')
    //   notification.error({
    //     message: 'Forbidden',
    //     description: data.message
    //   })
    }
    if (error.response.status === 401) {
      this.$message({
        type: "error",
        message: "登录失败，请检查登录信息！",
      });
      console.log('登录失败，请检查登录信息！')
    //   notification.error({
    //     message: 'Forbidden',
    //     description: data.message
    //   })
    }
    if (error.response.status === 500) {
      if (data.message.length > 0) {
        console.error(data.message)
      }
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
    //   notification.error({
    //     message: 'Unauthorized',
    //     description: 'Authorization verification failed'
    //   })
    //   if (token) {
    //     store.dispatch('Logout').then(() => {
    //       setTimeout(() => {
    //         window.location.reload()
    //       }, 1500)
    //     })
    //   }
    console.log('Authorization verification failed')
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    // config.headers['Authorization'] = 'Bearer ' + token
    config.headers['Authorization'] =  token
  }
  return config
}, err)

/**
 * response interceptor
 * 所有请求统一返回
 */
service.interceptors.response.use((response) => {
  // console.log(response)
  if (response.request.responseType === 'blob') {
    // if(!response.data){
    //   response.request.responseType = ''
    // }
    return response
  }
  // console.log(response.data)
  const code = response.data.code
  if (code === 1011006 || code === 1011007 || code === 1011008 || code === 1011009) {
    // Modal.error({
    //   title: '提示：',
    //   content: response.data.message,
    //   okText: '重新登录',
    //   onOk: () => {
    //     Vue.ls.remove(ACCESS_TOKEN)
    //     window.location.reload()
    //   }
    // })
    console.error('重新登录')
  } else if (code === 1013002 || code === 1016002 || code === 1015002) {
    console.error(response.data.message)
    return response.data
  } else {
    return response.data
  }
}, err)


export {
  service as axios
}
