<template>
  <div id="contactus">
    <div class="contact-box">
      <div class="top"></div>
      <div class="center">
        <div style="" id="dituContent"></div>
        <div class="two" v-show="basicInformation">
          <div class="container">
            <div class="image">
              <p class="icon-cont"></p>
              <div class="text">
                <p>
                  公司地址：哈尔滨
                  哈尔滨经开区哈平路集中区松花路9号中国云谷软件园A5号楼4层405、408、410室
                </p>
                <p>固定电话：86-0-451-51035095-51035096-51035097</p>
                <p>电子邮件：rzlee@163.com</p>
                <p>邮政编码：150069</p>
              </div>
            </div>
          </div>
        </div>
        <div class="four" v-show="fd">
          <div class="gb" @click="guanbi('gb')">
            X
          </div>
          <div class="container">
            <div class="image">
              <p class="icon-cont"></p>
              <div class="four-text">
                <div class="four-text-box">
                  <div class="four-user">
                    <div class="four-user-box">
                      <div>
                        <span><span><img :src="require('../assets/name.png')" /></span></span>
                        <input spellcheck="false" type="text" placeholder="用户名" v-model="userName" />
                      </div>
                    </div>
                    <div class="four-password-box">
                      <div>
                        <span><span><img :src="require('../assets/password.png')" /></span></span>
                        <input spellcheck="false" type="password" placeholder="密码" v-model="userPwd" />
                      </div>
                    </div>
                    <div class="four-submit">
                      <div class="four-submit-box">
                        <span class="submit-btn" @click="submitBtnClick('list')">确认</span>
                        <span class="cancel-btn" @click="cancelBtnClick()">取消</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="four-text-boxs">
                账号密码获取请联系公司人员获取
              </div>
            </div>
          </div>
        </div>
        <div class="lb">
          <el-carousel :interval="4000" type="card" width="5" height="8rem">
            <el-carousel-item v-for="(item, index) in contentArr" :key="index">
              <div class="lbt" :style="'background:url(' + contentArr[index].rightImg + ')'"></div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="Qr-code">
          <div class="Qr-code-box" v-show="Qrcode">
            <div class="Qr-code-container">
              <div class="Qr-code-image">
                <p class="Qr-code-icon-cont"></p>
                <div class="Qr-code-text">
                  <div class="Qr-code-text-box"></div>
                </div>
                <div class="center-button" @click="fangda('ck')">
                  相关业务登录
                </div>
                <div class="Qr-code-text-boxs">
                  哈尔滨神州精英科技开发有限公司公众号二维码
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center-list" v-show="download">
          <div class="center-list-Shut" @click="Shut('gb')">
            X
          </div>
          <div class="center-list-box">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column fixed prop="createTime" label="日期" width="200">
              </el-table-column>
              <el-table-column prop="fileName" label="文件名称" width="400">
              </el-table-column>
              <el-table-column prop="fileSize" label="文件大小" width="150">
              </el-table-column>
              <el-table-column prop="downloadNumber" label="文件下载次数" width="120">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template v-slot="scope">
                  <!-- <template> -->
                  <el-button @click="handleClick(scope.row)" type="text" size="small">下载</el-button>
                  <!-- <el-button type="text" size="small">编辑</el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="center-list-prompt" v-show="downloads">
          <div class="center-list-prompt-box">
            <div class="center-list-prompt-box-title" :class="{ 'active-btn': file == 1 }">
              请输入验证码
            </div>
            <div class="center-list-prompt-box-content">
              <div class="center-list-prompt-box-content-box" :class="{ 'active-btn': file == 1 }">
                <el-input v-model="input" placeholder="请输入内容"></el-input>
              </div>

              <el-row>
                <el-button type="primary" round @click="Download(id, pdfurl)">确定</el-button>
                <el-button type="info" round @click="Shutbox('gb')">取消</el-button>
              </el-row>
            </div>
            <div class="center-list-prompt-boxs" :class="{ 'active-btn': file == 1 }">
              验证码获取请联系公司人员
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div class="bottom-left-box">
            <a href="https://beian.miit.gov.cn/" target="123">
              黑ICP备20001581号-2
            </a>
          </div>
          <div class="bottom-left-box"></div>
        </div>
        <div class="bottom-right">
          ©哈尔滨神州精英科技开发有限公司 版权所有
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axios } from "../../request/axios";
// let BMap = window.BMap;
let BMapGL = window.BMapGL;
// let createIcon = window.createIcon();
// let createInfoWindow = window.createInfoWindow();
// let BMAP_ANCHOR_BOTTOM_RIGHT = window.BMAP_ANCHOR_BOTTOM_RIGHT;
// let BMAP_ANCHOR_TOP_LEFT = window.BMAP_ANCHOR_TOP_LEFT;
// let BMAP_NAVIGATION_CONTROL_LARGE = window.BMAP_NAVIGATION_CONTROL_LARGE;
// let BMAP_ANCHOR_BOTTOM_LEFT = window.BMAP_ANCHOR_BOTTOM_LEFT;
// let BMAP_ANIMATION_BOUNCE = window.BMAP_ANIMATION_BOUNCE;
// let BMAP_EARTH_MAP = window.BMAP_EARTH_MAP;
// let BMap_Symbol_SHAPE_POINT = window.BMap_Symbol_SHAPE_POINT;

export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333,
        },
      ],
      dialogFormVisible: false,
      download: false,
      downloads: false,
      basicInformation: true,
      fd: false,
      a: 1,
      userName: "",
      userPwd: "",
      file: "",
      input: "",
      id: "",
      pdfurl: "",
      contentArr: [
        { id: 1, rightImg: require("../assets/tu1.png") },
        { id: 2, rightImg: require("../assets/tu2.png") },
        { id: 3, rightImg: require("../assets/tu3.png") },
      ],
      Qrcode: true,
    };
  },
  methods: {
    handleClick(row) {
      this.id = row.id;
      console.log(this.file);
      console.log(this.a);
      this.pdfurl = row.fileName;
      console.log(this.id);
      console.log(this.pdfurl);
      if (row !== "") {
        this.downloads = true;
      }
    },
    Shutbox(pname) {
      if (pname === "gb") {
        this.downloads = false;
      }
    },
    fangda(pname) {
      if (pname === "ck") {
        this.fd = true;
        this.Qrcode = false;
        // this.basicInformation = false;
      }
    },
    guanbi(pname) {
      if (pname === "gb") {
        this.fd = false;
        this.basicInformation = true;
        this.Qrcode = true;
      }
    },
    Shut(pname) {
      if (pname === "gb") {
        this.download = false;
        this.fd = false;
        this.Qrcode = true;
      }
    },
    submitBtnClick(pname) {
      if (!this.userName) {
        this.$message({
          showClose: true,
          message: "用户名不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.userPwd) {
        this.$message({
          showClose: true,
          message: "密码不能为空",
          type: "warning",
        });
        return;
      }
      axios({
        method: "post",
        url: "/login",
        params: {
          username: this.userName,
          password: this.userPwd,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((res) => {
          console.log(res);
          this.file = res.data.file;
          if (!res.code == 200) {
            return;
          }
          if (pname === "list") {
            this.download = true;
          }
          localStorage.setItem("token", res.data.token);
          if (res.data.file == 1) {
            this.input = "szjy666";
          } else {
            this.input = "";
          }
          this.list();
        })
        .catch((err) => {
          this.$alert(
            "网络连接异常，请检查网络，或联系服务器管理员",
            "！！！注意",
            {
              confirmButtonText: "确定",
              callback: () => {
                this.resetCaptcha = false;
                this.userCaptcha = "";
                this.userCaptchaValiShow = false;
                this.$nextTick(() => {
                  this.resetCaptcha = true;
                });
              },
            }
          );
          console.log(err);
        });
    },

    list() {
      axios({
        method: "get",
        url: "/fileManage/findFileList",
      }).then(
        (res) => {
          this.tableData = res.data;
          // console.log(res);
          // console.log(res.data);
          // console.log(res.data.id);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    Download(id, pdfurl) {
      if (!this.input) {
        this.$message({
          showClose: true,
          message: "验证码不能为空",
          type: "warning",
        });
        return;
      }
      console.log(this.input);
      axios({
        method: "get",
        url: `/randomCode/verify?id=${id}&code=${this.input}`,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        responseType: "blob",
      }).then(
        (res) => {
          // console.log(res);
          let read = new FileReader(); //读取文件的类  返回一个实例
          read.readAsText(res.data, "utf-8"); //把文件内容读取成字符串
          read.onload = (e) => {
            //当文件内容读取完成后，触发的事件
            try {
              //尝试把读取文件的内容转换成对象---就是看看读取文件的内容是否为json格式，
              //是json格式的时候表明后端返回的不是文件而是提示信息将提示信息输出提示给用户
              //如果不是json格式表明是文件内容，json.parse方法将报错执行catch里面的方法下载文件。
              let result = JSON.parse(e.currentTarget.result);
              this.$message({
                showClose: true,
                message: result.message,
                type: "warning",
              });
            } catch (error) {
              // console.log(error)
              let url = window.URL.createObjectURL(new Blob([res.data]));
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              link.setAttribute("download", pdfurl);
              document.body.appendChild(link);
              link.click();
              this.downloads = false;
            }
          };

          // this.download = false;
          // this.fd = false;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    go(e) {
      window.location.href = e;
    },
  },
  mounted() {
    // this.baiduMap();
    //创建地图函数：

    let createMap = () => {
      // console.log('BMap',BMap);
      console.log('BMapGL', BMapGL);
      // new BMapGL.Map
      var map = new BMapGL.Map("dituContent"); //在百度地图容器中创建一个地图
      var point = new BMapGL.Point(126.644233, 45.635063); //定义一个中心点坐标
      map.centerAndZoom(point, 17); //设定地图的中心点和坐标并将地图显示在地图容器中
      var marker = new BMapGL.Marker(new BMapGL.Point(126.6436, 45.63465)); //创建marker对象
      map.addOverlay(marker); //在地图中添加marker
      marker.enableDragging(); //marker可拖拽
      var opts = {
        position: new BMapGL.Point(126.6436, 45.63465), // 指定文本标注所在的地理位置
        offset: new BMapGL.Size(10, -10), //设置文本偏移量
      };
      var label = new BMapGL.Label("哈尔滨神州精英科技开发有限公司", opts); // 创建文本标注对象
      label.setStyle({
        color: "#000",
        fontSize: "12px",
        height: "40px",
        fontWeight: "600",
        lineHeight: "36px",
        fontFamily: "微软雅黑",
        border: "none",
        borderRadius: "5px",
        padding: "2px 5px",
        background: "#77777780",
        boxShadow: "1px 1px 5px #000",
      });
      map.addOverlay(label);

      var marker1 = new BMapGL.Marker(new BMapGL.Point(126.641107, 45.633449)); //创建marker对象
      map.addOverlay(marker1); //在地图中添加marker
      marker1.enableDragging(); //marker可拖拽
      var opts1 = {
        position: new BMapGL.Point(126.641107, 45.633449), // 指定文本标注所在的地理位置
        offset: new BMapGL.Size(10, -10), //设置文本偏移量
      };
      var label1 = new BMapGL.Label("220路公交车（中国云谷）", opts1); // 创建文本标注对象
      label1.setStyle({
        color: "#000",
        fontSize: "12px",
        height: "40px",
        fontWeight: "600",
        lineHeight: "36px",
        fontFamily: "微软雅黑",
        border: "none",
        borderRadius: "5px",
        padding: "2px 5px",
        background: "#77777780",
        boxShadow: "1px 1px 5px #000",
      });
      map.addOverlay(label1);
      var marker2 = new BMapGL.Marker(new BMapGL.Point(126.643784, 45.637281)); //创建marker对象
      map.addOverlay(marker2); //在地图中添加marker
      marker2.enableDragging(); //marker可拖拽
      var opts2 = {
        position: new BMapGL.Point(126.643784, 45.637281), // 指定文本标注所在的地理位置
        offset: new BMapGL.Size(10, -10), //设置文本偏移量
      };
      var label2 = new BMapGL.Label("343路公交车，地铁一号线（镜泊路）", opts2); // 创建文本标注对象
      label2.setStyle({
        color: "#000",
        fontSize: "12px",
        height: "40px",
        fontWeight: "600",
        lineHeight: "36px",
        fontFamily: "微软雅黑",
        border: "none",
        borderRadius: "5px",
        padding: "2px 5px",
        background: "#77777780",
        boxShadow: "1px 1px 5px #000",
      });
      map.addOverlay(label2);


      // map.setMapType(BMAP_EARTH_MAP);
      // console.log(BMAP_EARTH_MAP);
      // marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画

      map.addOverlay(marker);
      window.map = map; //将map变量存储在全局
      map.enableScrollWheelZoom(true);
      map.setHeading(20);
      map.setTilt(65);
      // var busline = new BMapGL.BusLineSearch(map, {
      //   renderOptions: { map: map, panel: "r-result" },
      //   onGetBusListComplete: function (result) {
      //     if (result) {
      //       var fstLine = result.getBusListItem(0);//获取第一个公交列表显示到map上
      //       busline.getBusLine(fstLine);
      //     }
      //   }
      // });
      // function busSearch() {
      //   var busName = [220];
      //   busline.getBusList(busName);
      // }
      // setTimeout(function () {
      //   busSearch();
      // }, 1500);
      // map.enableDragging(); //启用地图拖拽事件，默认启用(可不写)
      // map.disableDragging(); //禁用地图拖拽事件，默认启用
      // // map.enableScrollWheelZoom(); //启用地图滚轮放大缩小
      // map.enableDoubleClickZoom(); //启用鼠标双击放大，默认启用(可不写)
      // map.enableKeyboard(); //启用键盘上下左右键移动地图
      // var ctrl_sca = new BMapGL.ScaleControl({ anchor: BMAP_ANCHOR_BOTTOM_LEFT });
      // map.addControl(ctrl_sca);
      // var ctrl_nav = new BMapGL.NavigationControl({
      //   anchor: BMAP_ANCHOR_TOP_LEFT,
      //   type: BMAP_NAVIGATION_CONTROL_LARGE,
      // });
      // map.addControl(ctrl_nav);
      // //向地图中添加缩略图控件
      // var ctrl_ove = new BMapGL.OverviewMapControl({
      //   anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
      //   isOpen: 1,
      // });
      // map.addControl(ctrl_ove);
    };
    createMap();
  },
};
</script>
<style lang="scss">
#contactus {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgb(18, 158, 0) 0%,
      rgb(0, 77, 0) 15%,
      rgb(0, 51, 0) 40%);
  // background: url(../assets/Contact-us-green.jpg);
  background: url(../assets/Contact-us-green.jpg);
  background-size: 100% 100%;
  position: relative;

  .contact-box {
    width: 100%;
    height: 100%;
    position: relative;

    // background: #77777780;
    // font-weight: 600;
    // box-shadow: 1px 1px 5px #fff;
    .top {
      height: 7%;
    }

    .center {
      width: 100%;
      height: 83%;
      position: relative;

      .one {
        position: absolute;
        top: 15%;
        left: 5%;
        width: 25%;
        height: 80%;
        color: white;
        text-align: center;

        .container {
          width: 100%;
          height: 100%;
          background-position: center;
          position: relative;
          transition: all 0.5s;

          .image {
            width: 100%;
            height: 100%;
            position: relative;

            .icon-cont {
              position: absolute;
              left: 35%;
              top: 10%;
              border: 0.2rem solid rgba(255, 255, 255, 0.3);
              border-radius: 50%;
              width: 2rem;
              height: 2rem;
              display: block;
              text-align: center;

              z-index: 50;
              box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                0 0 0 0px rgba(3, 108, 129, 0.5);
            }

            &:hover .icon-cont {
              animation: shady 4s linear infinite;
            }

            @keyframes shady {
              0% {
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }

              20% {
                box-shadow: 0 0 0 100px rgba(255, 255, 255, 0),
                  0 0 0 0px rgba(3, 108, 129, 0);
              }

              20.1% {
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }

              50% {
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }

              70% {
                box-shadow: 0 0 0 100px rgba(255, 255, 255, 0),
                  0 0 0 0px rgba(3, 108, 129, 0);
              }

              70.1% {
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }

              100% {
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }
            }
          }
        }
      }

      .two {
        position: absolute;
        top: 1.5%;
        left: 2%;
        width: 30%;
        height: 97%;
        color: white;
        -webkit-perspective: 900px;
        text-align: left;
        line-height: 0.5rem;

        .container {
          width: 100%;
          height: 100%;
          background: rgba(5, 5, 5, 0.425);
          background-position: center;
          position: relative;
          transition: all 0.5s;
          border-radius: 0.5rem;

          .image {
            width: 100%;
            height: 100%;
            position: relative;

            .text {
              position: absolute;
              top: 23%;
              line-height: 0.5rem;
              color: rgb(255, 255, 255);
              font-size: 0.2rem;
              padding: 0 0.5rem;
            }

            .icon-cont {
              position: absolute;
              left: 35%;
              top: 1%;
              border: 0.2rem solid rgba(255, 255, 255, 0.3);
              border-radius: 50%;
              width: 2rem;
              height: 2rem;
              display: block;
              text-align: center;
              z-index: 50;
              box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                0 0 0 0px rgba(3, 108, 129, 0.5);
            }

            &:hover .icon-cont {
              animation: shady 4s linear infinite;
            }

            @keyframes shady {
              0% {
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }

              20% {
                box-shadow: 0 0 0 100px rgba(255, 255, 255, 0),
                  0 0 0 0px rgba(3, 108, 129, 0);
              }

              20.1% {
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }

              50% {
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }

              70% {
                box-shadow: 0 0 0 100px rgba(255, 255, 255, 0),
                  0 0 0 0px rgba(3, 108, 129, 0);
              }

              70.1% {
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }

              100% {
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }
            }
          }
        }
      }

      .four {
        position: absolute;
        top: 15%;
        right: 2%;
        width: 25%;
        height: 70%;
        color: white;
        -webkit-perspective: 900px;
        text-align: center;
        z-index: 999;

        .container {
          width: 100%;
          height: 100%;
          background: rgba(5, 5, 5, 0.452);
          background-position: center;
          position: relative;
          transition: all 0.5s;
          border-radius: 0.5rem;

          .icon-cont {
            position: absolute;
            left: 35%;
            top: 5%;
            border: 0.2rem solid rgba(255, 255, 255, 0.3);
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            display: block;
            text-align: center;
            z-index: 50;
            box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
              0 0 0 0px rgba(3, 108, 129, 0.5);
          }

          &:hover .icon-cont {
            animation: shady 4s linear infinite;
          }

          @keyframes shady {
            0% {
              box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                0 0 0 0px rgba(3, 108, 129, 0.5);
            }

            20% {
              box-shadow: 0 0 0 100px rgba(255, 255, 255, 0),
                0 0 0 0px rgba(3, 108, 129, 0);
            }

            20.1% {
              box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                0 0 0 0px rgba(3, 108, 129, 0.5);
            }

            50% {
              box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                0 0 0 0px rgba(3, 108, 129, 0.5);
            }

            70% {
              box-shadow: 0 0 0 100px rgba(255, 255, 255, 0),
                0 0 0 0px rgba(3, 108, 129, 0);
            }

            70.1% {
              box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                0 0 0 0px rgba(3, 108, 129, 0.5);
            }

            100% {
              box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                0 0 0 0px rgba(3, 108, 129, 0.5);
            }
          }

          .four-text {
            width: 6rem;
            height: 3rem;
            position: absolute;
            top: 2.5rem;
            padding: 0 0.3rem;

            .four-text-box {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;

              .four-user {
                flex: 1;
                padding: 0.1rem 0;

                .four-user-box {
                  width: 100%;
                  height: 0.8rem;
                  padding: 0.05rem 0;

                  >div {
                    width: 4rem;
                    height: 0.6rem;
                    background: white;
                    padding: 0 0 0 0.6rem;
                    position: relative;
                    border: 1px solid blue;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >span {
                      //人形图标
                      display: inline-block;
                      width: 0.6rem;
                      height: 0.6rem;
                      position: absolute;
                      left: 0;
                      top: 0;

                      >span {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;

                        >img {
                          width: 0.2rem;
                          height: 0.2rem;
                        }
                      }
                    }

                    >input {
                      //用户输入框
                      width: 3.3rem;
                      height: 99%;
                      outline: none;
                      border: 0;
                      font-size: 0.2rem;
                    }
                  }
                }

                .four-password-box {
                  width: 100%;
                  height: 1rem;
                  padding: 0.05rem 0;

                  >div {
                    width: 4rem;
                    height: 0.6rem;
                    background: white;
                    padding: 0 0 0 0.6rem;
                    position: relative;
                    border: 1px solid blue;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >span {
                      //锁头型图标
                      display: inline-block;
                      width: 0.6rem;
                      height: 0.6rem;
                      position: absolute;
                      left: 0;
                      top: 0;

                      >span {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;

                        >img {
                          width: 0.3rem;
                          height: 0.3rem;
                        }
                      }
                    }

                    >input {
                      //用户密码输入框
                      width: 3.3rem;
                      height: 99%;
                      outline: none;
                      border: 0;
                      font-size: 0.2rem;
                    }
                  }
                }
              }

              .four-submit-box {
                width: 4rem;
                height: 0.6rem;
                margin: auto;
                margin-top: 0.15rem;
                display: flex;
                justify-content: space-evenly;

                >span {
                  //用span 做的按钮样式
                  width: 1.5rem;
                  height: 0.6rem;
                  /* padding: 0.1rem; */
                  background: #409eff;
                  text-align: center;
                  line-height: 0.6rem;
                  font-size: 0.3rem;
                  color: #fff;
                  border-radius: 2px;
                  cursor: pointer;
                  user-select: none;
                  opacity: 1;

                  &:active {
                    opacity: 0.6;
                    color: #eee;
                  }

                  &:hover {
                    /* box-shadow: 0 0 5px rgba(81, 203, 238, 1);*/
                    box-shadow: 0 0 10px rgb(7, 193, 250) inset;
                  }
                }
              }
            }
          }

          .four-text-boxs {
            width: 6rem;
            height: 1rem;
            position: absolute;
            bottom: 0.5rem;
            text-align: center;
            font-size: 0.25rem;
            line-height: 1rem;
          }
        }

        .gb {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 0;
          right: 0;
          text-align: center;
          line-height: 30px;
          color: rgb(255, 255, 255);
          background: #8a8888;
          cursor: pointer;
          z-index: 111;

          &:hover {
            background: rgb(0, 0, 0);
            cursor: pointer;
            color: #fff;
          }

          &:active {
            color: #fff;
            background: rgb(0, 0, 0);
          }
        }
      }

      .Qr-code {
        position: absolute;
        top: 1.5%;
        right: 2%;
        width: 25%;
        height: 97%;
        color: white;
        -webkit-perspective: 900px;
        text-align: center;
        line-height: 0.5rem;

        // background: #409eff;
        .Qr-code-box {
          width: 100%;
          height: 100%;
          color: white;
          -webkit-perspective: 900px;
          text-align: center;
          line-height: 0.5rem;

          .Qr-code-container {
            width: 100%;
            height: 100%;
            background: rgba(5, 5, 5, 0.425);
            background-position: center;
            position: relative;
            transition: all 0.5s;
            border-radius: 0.5rem;

            .Qr-code-image {
              width: 100%;
              height: 100%;
              position: relative;

              .Qr-code-text {
                width: 100%;
                height: 60%;
                position: absolute;
                top: 25%;
                line-height: 0.5rem;
                color: rgb(255, 255, 255);
                font-size: 0.2rem;
                padding: 1.5rem;

                .Qr-code-text-box {
                  width: 100%;
                  height: 100%;
                  background: url(../assets/erweima.jpg);
                  background-size: 100% 100%;
                }
              }

              .Qr-code-icon-cont {
                position: absolute;
                left: 32%;
                top: 7%;
                border: 0.2rem solid rgba(255, 255, 255, 0.3);
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                display: block;
                text-align: center;
                z-index: 50;
                box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                  0 0 0 0px rgba(3, 108, 129, 0.5);
              }

              &:hover .Qr-code-icon-cont {
                animation: shady 4s linear infinite;
              }

              @keyframes shady {
                0% {
                  box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                    0 0 0 0px rgba(3, 108, 129, 0.5);
                }

                20% {
                  box-shadow: 0 0 0 100px rgba(255, 255, 255, 0),
                    0 0 0 0px rgba(3, 108, 129, 0);
                }

                20.1% {
                  box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                    0 0 0 0px rgba(3, 108, 129, 0.5);
                }

                50% {
                  box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                    0 0 0 0px rgba(3, 108, 129, 0.5);
                }

                70% {
                  box-shadow: 0 0 0 100px rgba(255, 255, 255, 0),
                    0 0 0 0px rgba(3, 108, 129, 0);
                }

                70.1% {
                  box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                    0 0 0 0px rgba(3, 108, 129, 0.5);
                }

                100% {
                  box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5),
                    0 0 0 0px rgba(3, 108, 129, 0.5);
                }
              }

              .center-button {
                width: 1.5rem;
                height: 1.5rem;
                line-height: 0.5rem;
                padding: 0.3rem;
                /* background: #409eff; */
                color: white;
                font-size: 0.25rem;
                text-align: center;
                position: absolute;
                right: 38.8%;
                top: 9.6%;
                cursor: pointer;
                border-radius: 0.8rem;
                z-index: 99;

                &:hover {
                  // color: rgb(143, 143, 143);
                  background: #0000007c;
                }

                &:active {
                  color: #fff;
                  background: rgb(0, 0, 0);
                  box-shadow: 0 0 10px rgb(7, 193, 250) inset;
                }
              }

              .Qr-code-text-boxs {
                width: 100%;
                height: 1rem;
                font-size: 0.25rem;
                color: #fff;
                position: absolute;
                bottom: 10%;
              }
            }
          }
        }
      }

      .center-list {
        width: 60%;
        height: 80%;
        background: #fff;
        position: absolute;
        top: 10%;
        left: 20%;
        padding: 0.35rem;
        z-index: 999;

        .center-list-box {
          width: 100%;
          height: 100%;
          border-radius: 0.1rem;
          background: rgba(236, 236, 236, 0.842);
          box-shadow: 0 0 10px;
          padding: 0.25rem;
        }

        .center-list-Shut {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 0;
          right: 0;
          text-align: center;
          line-height: 30px;
          color: rgb(255, 255, 255);
          background: #8a8888;
          cursor: pointer;
          z-index: 111;

          &:hover {
            background: rgb(0, 0, 0);
            cursor: pointer;
            color: #fff;
          }

          &:active {
            color: #fff;
            background: rgb(0, 0, 0);
          }
        }
      }

      .center-list-prompt {
        width: 100%;
        height: 100%;
        background: rgba(66, 65, 65, 0.664);
        position: absolute;
        top: 0;
        left: 0;
        padding: 3.5rem 10rem;
        z-index: 9999;

        .center-list-prompt-box {
          width: 100%;
          height: 100%;
          background: #fff;
          position: relative;

          .el-row {
            position: absolute;
            top: 1.3rem;
            left: 1rem;
          }

          .center-list-prompt-box-title {
            width: 100%;
            height: 0.5rem;
            background: #cacacab2;
            text-align: center;
            font-size: 0.2rem;
            line-height: 0.5rem;
            color: #000;
            font-weight: 600;
          }

          .center-list-prompt-box-content {
            width: 100%;
            height: 1.5rem;
            padding: 0.1rem 0.8rem;

            .center-list-prompt-box-content-box {
              width: 100%;
              height: 0.5rem;

              .active-btn {
                display: none;
              }
            }
          }

          .center-list-prompt-boxs {
            width: 100%;
            height: 0.8rem;
            padding: 0.1rem;
            font-size: 0.15rem;
            text-align: center;
          }

          .active-btn {
            display: none;
          }
        }
      }
    }

    .bottom {
      height: 10%;
      width: 100%;
      background: black;
      line-height: 0.6rem;
      display: flex;

      .bottom-left {
        flex: 1;
        display: flex;

        .bottom-left-box {
          //flex: 1;
          width: 4rem;
          padding: 10px 20px;
          text-align: left;
          color: white;
          z-index: 30;
          font-size: 0.25rem;

          >a {
            width: 100%;
            height: 100%;
            text-decoration: none;
            color: #fff;
          }
        }
      }

      .bottom-right {
        flex: 1;
        padding: 10px 20px;
        text-align: right;
        color: white;
        z-index: 30;
        font-size: 0.25rem;
      }
    }
  }

  #dituContent {
    width: 6.2rem;
    height: 5rem;
    border: #ccc solid 1px;
    position: absolute;
    left: 4%;
    top: 48%;
    z-index: 1;

    >div:nth-child(1) {
      width: 100%;
      height: 100%;

      >div:nth-child(2) {
        width: 100% !important;
        height: 100% !important;

        >div:nth-child(4) {
          width: 100% !important;
          height: 100% !important;
        }

        >div:nth-child(5) {
          width: 100% !important;
          height: 100% !important;

          .BMap_Marker {
            width: 50px !important;
            height: 50px !important;

            //left: 270px  !important;
            //top: 100px !important;
            >div {
              width: 25px !important;
              height: 50px !important;

              >img {
                margin-top: 20px !important;
              }
            }
          }
        }
      }
    }
  }

  .lb {
    width: 9rem;
    height: 10rem;
    position: absolute;
    top: 10%;
    left: 34%;
    background: rgba(8, 8, 8, 0);
  }

  .active-btn {
    display: none;
  }

  .el-carousel__container {}

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 4rem;
    margin: 0;
  }

  .lbt {
    width: 100%;
    height: 100%;
    background-size: 100% 100% !important;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
</style>
