import { createApp } from 'vue'
import 'element3/lib/theme-chalk/index.css'
import Element3 from 'element3'
// import BaiduMap from 'vue-baidu-map'

// import 'element3/lib/theme-chalk/index.css'
// import * as obj from 'vue'
import App from './App.vue'
// import Element3 from 'element3'

// console.log(obj)
const app = createApp(App)

// let a = 123
// let pro = Object.getPrototypeOf(app)
// // pro.a = a
// // console.log(pro)
// pro.Button = Button
app.use(Element3)
// app.use(BaiduMap, {
//   ak: "UL2GuIw2uFwA6vW37dDG0TCG5tnUl56l"
// })
app.mount('#app')


