<template>
  <div id="wisdom">
    <div class="dian"></div>
    <div class="wisdom-box">
      <div class="top"></div>
      <div class="center">
        <div
          class="center-box"
          :style="'top:-' + topval + '%'"
          :class="{ 'trans-active': topval !== 0 }"
        >
          <div class="wn">
            <div class="one">
              <div>{{ title }}</div>
              <div v-html="contentArr.message"></div>
            </div>
            <div class="two">
              <img :src="backgroundImg" alt="" />
            </div>
            <div class="three">
              <img src="../assets/bg-wisdom.png" alt="" />
            </div>
          </div>
          <!-- <div class="wn">
            <div class="one"></div>
            <div class="two">
              <img :src="require('../assets/' + 'wisdom1' + '.png')" alt="" />
            </div>
            <div class="three">
              <div class="left">
                <div class="hexagon">
                  智慧林业
                </div>
                <div class="hexagon">
                  智慧生态
                </div>
              </div>
              <div class="middle">
                <div class="hexagon">智慧气象</div>
                <div class="hexagon">
                  智慧农业
                </div>
                <div class="hexagon">
                  智慧渔业
                </div>
              </div>
              <div class="right">
                <div class="hexagon">
                  智慧应急
                </div>
                <div class="hexagon">
                  智慧水利
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div class="bottom-left-box">
            <a href="https://beian.miit.gov.cn/" target="123">
              黑ICP备20001581号-2
            </a>
          </div>
          <div class="bottom-left-box"></div>
        </div>
        <div class="bottom-right">
          <div class="bottom-right-left">
            业务联系电话：13945046615
          </div>
          <div class="bottom-rights">
            ©哈尔滨神州精英科技开发有限公司 版权所有
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axios } from "../../request/axios";
export default {
  props: {
    height: {
      default: 83,
      type: Number,
    },
    lineNum: {
      default: 1,
      type: Number,
    },
  },
  data: function() {
    return {
      firstPage: {},
      contentArr: [{}],
      backgroundImg: "",
      title: "",
      num: 0,
      topval: 0,
    };
  },
  computed: {
    transform: function() {
      return "translateY(-" + this.num * this.height + "%)";
    },
  },
  mounted() {
    // this.lunbo();
  },
  created() {
    axios({
      method: "get",
      url: "/wisdomContent/selectWisdomContent",
    }).then(
      (res) => {
        // console.log(res)
        // console.log(res.titles)
        // this.contentArr.title = res.title
        // console.log(this.contentArr)
        // let wisdomContents = JSON.parse(JSON.stringify(res.wisdomContents));
        // this.firstPage = JSON.parse(JSON.stringify(wisdomContents[0]));
        // let contentArr = [];
        // for (let i = 1; i < wisdomContents.length; i++) {
        //   contentArr.push(wisdomContents[i]);
        // }
        // this.contentArr = JSON.parse(JSON.stringify(contentArr));
        this.backgroundImg = res.wisdomContents[0].backgroundImg;
        this.title = res.titles[0];
        let strArr = res.wisdomContents[0].manBodyA.split("&");
        // console.log(strArr)
        // // let strArr = res.wisdomContents.manBodyA.
        res.message = "";
        for (let i = 0; i < strArr.length; i++) {
          res.message += `<p>${strArr[i]}</p>`;
        }
        this.contentArr = JSON.parse(JSON.stringify(res));

        // this.contentArr = res.wisdomContents
        console.log(this.contentArr);
      },
      (err) => {
        console.error(err);
      }
    );
  },
  methods: {
    // lunbo() {
    //   this.timer = setInterval(() => {
    //     if (this.topval >= 600) {
    //       this.topval = 0;
    //     } else {
    //       this.topval += 100;
    //     }
    //   }, 3500);
    // },
    // mouseOver() {
    //   clearInterval(this.timer);
    //   this.timer = null;
    // },
    // mouseOut() {
    //   clearInterval(this.timer);
    //   this.lunbo();
    // },
    go(e) {
      window.location.href = e;
    },
  },
};
</script>

<style lang="scss">
#wisdom {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    rgb(18, 158, 0) 0%,
    rgb(0, 77, 0) 15%,
    rgb(0, 51, 0) 40%
  );
  position: relative;
  .dian {
    flex: 0.5;
    height: 60%;
    position: absolute;
    left: 0;
    top: 50%;
    padding-left: 0.5rem;
    transform: translateY(-50%);
    z-index: 20;
    ul {
      list-style: none;
      li {
        width: 0.2rem;
        height: 0.2rem;
        border-radius: 50%;
        background: #fff;
        margin: 0.7rem 0;
        &.active-dian {
          background: #6b8e23;
        }
      }
    }
  }
  .wisdom-box {
    width: 100%;
    height: 100%;
    position: relative;
    .top {
      height: 7%;
    }
    .center {
      height: 83%;
      width: 100%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .center-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        &.trans-active {
          transition: all 0.5s linear;
        }
        .wn {
          height: 100%;
          width: 100%;
          position: relative;
          .one {
            width: 57%;
            height: 90%;
            z-index: 999;
            top: 5%;
            left: 10%;
            color: white;
            //transform: translate(-50%, -50%);
            padding: 40px;
            position: absolute;
            div:nth-child(1) {
              color: white;
              line-height: 0.6rem;
              font-size: 0.25rem;
              font-weight: bolder;
              text-shadow: 2px 2px 2px black;
            }
            div:nth-child(2) {
              p {
                line-height: 0.6rem;
                font-size: 0.25rem;
                color: white;
                text-indent: 2em;
                text-shadow: 2px 2px 2px black;
              }
            }
          }
          .two {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            > img {
              width: 100%;
              height: 100%;
              // object-fit: none;
            }
          }
          .three {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0%;
            left: 0%;
            img {
              height: 100%;
              width: 100%;
              position: absolute;
              width: 57%;
              height: 90%;
              top: 5%;
              left: 10%;
            }
          }
        }
      }
    }
    .bottom {
      height: 10%;
      width: 100%;
      background: black;
      line-height: 0.6rem;
      display: flex;
      .bottom-left {
        flex: 1;
        display: flex;
        .bottom-left-box {
          //flex: 1;
          width: 4rem;
          padding: 10px 20px;
          text-align: left;
          color: white;
          z-index: 30;
          font-size: 0.25rem;
          > a {
            width: 100%;
            height: 100%;
            text-decoration: none;
            color: #fff;
          }
        }
      }
      .bottom-right {
        flex: 1;
        padding: 10px 20px;
        text-align: right;
        color: white;
        z-index: 30;
        font-size: 0.25rem;
        display: flex;
        .bottom-right-left {
          flex: 1;
          font-size: 0.25rem;
          color: #fff;
          text-align: left;
        }
        .bottom-rights {
          flex: 1;
          font-size: 0.25rem;
          color: #fff;
          text-align: right;
        }
      }
    }
  }
}
</style>
